@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --color-primary: #7b61ff;
        --color-secondary: #878787;
        /*--color-heading: #f5f5f7;*/
        --color-heading: #ece8e1;
        --color-text: rgba(255, 255, 255, 0.6);
        --color-background: #1c1c1c;

        --font-inter: "Montserrat", sans-serif;
        --font-cousine: "Montserrat", sans-serif;
    }
}
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/static/Montserrat-Medium.ttf') format('truetype');
}

@layer utilities {
    /* This two helpers apply a -50% to the translate x/y, and add
     * a --y/--x css variable to it. This util is only here to make writing this class
     * a bit easier to read in the HTML :)   */
    .translate-y-centered-offset {
        @apply translate-y-[calc(-50%_+_var(--y))];
    }
    .translate-x-centered-offset {
        @apply translate-x-[calc(-50%_+_var(--x))];
    }
    .rotate-90 {
        transform: rotate(90deg);
    }
    .rotate-180 {
        transform: rotate(180deg);
    }
    .rotate-270 {
        transform: rotate(270deg);
    }
}


body {
    background-color: #1c1c1c;
}

/*TODO corriger ce bug*/


.home-title {
    color: var(--color-background);
    font-weight: bold;
    text-transform: uppercase;
}
.home-text {
    color: var(--color-background);
    font-weight: bold;
}

.p-scroll {
    font-weight: bold;
    color: var(--color-background);
    position: fixed;
    left: 6vw;
    bottom: 10vh;
    text-transform: uppercase;
    border-top: solid 2px;
    padding: 5px;
    padding-right: 3px;
}

.aside {
    pointer-events: none;
    position: fixed;
    z-index: 10;
}
.aside-bot {
    bottom: 0;
    left: 0;
}
.aside-top {
    top: 0;
    left: 0;
}
.header {
    user-select: none;
    background-image: url("/public/got.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: calc(100vw - 6px);
    height: 100vh;
}
.navbar {
    position: sticky;
    top: 0;
    z-index: 20;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    background-color: var(--color-background);
    padding: 10px;
    width: 100%;
    font-family: 'Montserrat';
    user-select: none;
}
.navbar-logo {
    height: 40px;
    padding: 5px;
    border-radius: 10px;
}
.navbar-logo-aspig {
    height: 40px;
    position: absolute;
    left: 0;
    margin-left: 10px;
}
.navbar-login-container{
    display: flex;
    /*flex-direction: column;*/
    position: absolute;
    right: 0;
    margin-right: 10px;
}
.navbar-login-container {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 0;
    margin-right: 20px;
}
.navbar-logout-logo {
    height: 25px;
}

.networks {
    font-weight: bold;
}
.networks img, .networks p {
    pointer-events: none;
    user-select: none;
}
.networks span {
}

.background {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: end;
    position: relative;
    z-index: -100;
}
.background>p{
    font-size: 3rem;
    position: absolute;
    margin: 3rem;
    opacity: 0.5;
}
.background-1 {
    height: 120vh;
    background-color: var(--color-heading);
    color: var(--color-background);
}
.background-2 {
    height: 120vh;
    background-color: var(--color-background);
    color: var(--color-heading);
}
.background-3 {
    height: 120vh;
    background-color: var(--color-heading);
    color: var(--color-background);
}
.background-4 {
    height: 120vh;
    background-color: var(--color-background);
    color: var(--color-heading);
}
/*.background-5 {*/
/*    height: 120vh;*/
/*    background-color: var(--color-heading);*/
/*    color: var(--color-background);*/
/*}*/
.background-tri {
    top: -1px;
}

.pagination {
    position: fixed;
    font-size: 3rem;
    bottom: 0;
    left: 0;
    margin: 3rem;
}
.pagination p {
    position: absolute;
    bottom: 0;
    left: 0;
}

/* Style de la barre de défilement */
::-webkit-scrollbar {
    width: 6px; /* Largeur de la barre de défilement */
    background: var(--color-background);
}

/* Style de la poignée de la barre de défilement */
::-webkit-scrollbar-thumb {
    background: #888; /* Couleur de fond de la poignée */
    border-radius: 6px; /* Coins arrondis de la poignée */
}

/* Lorsque l'utilisateur survole la poignée */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.updateScoreButton {
    background-color: white;
    border: 1px solid black;
    width: 200px;
    text-align: center;
    cursor: pointer;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
}